
import { pageNotFoundRoutes } from '@fwk-client/router/generic-routes';
import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';
import { Store } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';
import { gettersPath as shopGettersPath, types as shopTypes } from '@fwk-client/modules/shop/stores/shop';
import { gettersPath as agencyGettersPath, types as agencyTypes } from '@fwk-client/modules/hospitality/stores/agency';
import { gettersPath as siteGettersPath, types as siteTypes } from '@fwk-client/modules/cms/stores/site';
import { routes as shopRoutes , routesFromShortName as shopRoutesFromShortName } from './shop';
import { routes as authenticationRoutes , routesFromShortName as authenticationRoutesFromShortName } from './authentication';
import { routes as hospitalityRoutes , routesFromShortName as hospitalityRoutesFromShortName } from './hospitality';
import { routes as vigneronOnlineRoutes, routesFromShortName as vigneronOnlineRoutesFromShortName } from './vigneron-online';
import { defaultCmsRoute, routes as cmsRoutes , routesFromShortName as cmsRoutesFromShortName } from './cms';
import { gettersPath as vigneronOnlineGettersPath, types as vigneronOnlineTypes } from '../store/vigneron-online';

// We export all the routes
export function getRoutes(store:Store<any>):Array<RouteConfig> {
  // By default we only register page not found routes
  var routesToRegister:Array<RouteConfig> = [
    ...pageNotFoundRoutes
  ]

  // We get the site
  var site = store.getters[siteGettersPath[siteTypes.getters.GET_SITE]];
  if(site && site.code == "non-existing-site") {
    // We are on the non existing site
    var siteRoutes:any[] = store.getters[siteGettersPath[siteTypes.getters.GET_SITE_ROUTES]];
    routesToRegister = [
      ...siteRoutes
    ]
  }
  else if(site) {
    // We are on identified site

    // We get the site predefined routes
    var siteRoutes:any[] = store.getters[siteGettersPath[siteTypes.getters.GET_SITE_ROUTES]];
    var siteRoutesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(siteRoutes);

    // We check if the site has a shop to add shop dedicated routes
    var computedDefaultRoutes:Array<RouteConfig> = [];
    var defaultRoutesFromShortName:{[routeShortName:string]:RouteConfig} = {};
    var shop = store.getters[shopGettersPath[shopTypes.getters.GET_SHOP]];
    var vigneronOnlineSite = store.getters[vigneronOnlineGettersPath[vigneronOnlineTypes.getters.GET_VIGNERON_ONLINE_SITE]];
    if(vigneronOnlineSite) {
      // We are on vigneron-online
      computedDefaultRoutes = [
        ...vigneronOnlineRoutes
      ];
      defaultRoutesFromShortName = {...vigneronOnlineRoutesFromShortName};
    }
    else if(shop) {
      // We are on a CMS site with associated shop
      // We compute the shop dedicated routes
      computedDefaultRoutes = shopRoutes.map((shopRoute) => {
        var siteRoute = siteRoutesFromShortName[shopRoute.name];
        let mergedRoute = utils.mergeRouteConfigs(
          vigneronOnlineRoutesFromShortName[shopRoute.name], // We take the vigneron-online route definition as base
          shopRoute, // We apply shop specific infos
          siteRoute // We extend with site specific infos
        );
        if(mergedRoute.meta && mergedRoute.meta.hasPageContent) {
          // In case we have page content we delete the default componet
          if((mergedRoute as any).component) {
            delete (mergedRoute as any).component;
          }
        }
        return mergedRoute;
      });
      defaultRoutesFromShortName = {...shopRoutesFromShortName};
    }
    else {
      // We are in cms mode
      // We first compute the cms default routes
      computedDefaultRoutes = cmsRoutes.map((cmsRoute) => {
        var siteRoute = siteRoutesFromShortName[cmsRoute.name!];
        return utils.mergeRouteConfigs(
          cmsRoute, // We apply cms specific infos
          siteRoute // We extend with site specific infos
        )
      });
      defaultRoutesFromShortName = {...cmsRoutesFromShortName};
    }

    // We check if site has profile enabled
    var hasProfile:boolean = store.getters[siteGettersPath[siteTypes.getters.GET_SITE_HAS_PROFILE]];
    var computedAuthenticationRoutes:Array<RouteConfig> = [];
    if(hasProfile) {
      // We need to add the authentication routes
      computedAuthenticationRoutes = authenticationRoutes.map((authenticationRoutes) => {
        var siteRoute = siteRoutesFromShortName[authenticationRoutes.name];
        return utils.mergeRouteConfigs(
          authenticationRoutes, // We apply authentication route base
          siteRoute // We extend with site specific infos
        )
      })
    }

    // We check if site has hospitality enabled
    var agency = store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
    var computedHospitalityRoutes:Array<RouteConfig> = [];
    if(agency) {
      // We need to add the authentication routes
      computedHospitalityRoutes = hospitalityRoutes.map((hospitalityRoute) => {
        var siteRoute = siteRoutesFromShortName[hospitalityRoute.name];
        return utils.mergeRouteConfigs(
          hospitalityRoute, // We apply hospitality route base
          siteRoute // We extend with site specific infos
        )
      })
    }

    // We keep the site specific routes not already defined
    var computedSiteRoutes = siteRoutes.map((siteRoute) => {
      return utils.mergeRouteConfigs(
        defaultCmsRoute, // We add the default Cms route definition to get the Cms component
        siteRoute
      );
    }).filter((siteRoute:any) => {
      // Routes not available in default routes
      return defaultRoutesFromShortName[siteRoute.name] == undefined && 
          authenticationRoutesFromShortName[siteRoute.name] == undefined &&
          hospitalityRoutesFromShortName[siteRoute.name] == undefined;
    })

    routesToRegister = [
      ...pageNotFoundRoutes,
      ...computedSiteRoutes,
      ...computedAuthenticationRoutes,
      ...computedHospitalityRoutes,
      ...computedDefaultRoutes
    ]
  }

  // We update the routes to add localized version
  const supportedLanguages:string[] = store.getters['languages/' + languagesTypes.getters.GET_AVAILABLE_LANGUAGES]
  return utils.addLocalizedRoutes(routesToRegister, supportedLanguages);
}